import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Link } from 'gatsby'

const Wrapper = styled.div`
  text-align: center;
  border-radius: 5px;

  a {
    font-family: ${props => `${props.theme.fonts.head}`};
    box-shadow: inset 0 -3px 0 ${props => `${props.theme.colors.red}`},
      1px 2px 11px 0 rgba(0, 0, 0, 0.5);
    color: ${props => `${props.theme.colors.grey}`};
    font-size: 18px;
    background: white;
    font-weight: bold;
    text-decoration: none;
    padding: 0.25rem 1.5rem;
    display: inline-block;
    transition: all 0.3s ease-in-out;
    &:hover {
      box-shadow: inset 0 -6px 0 ${props => `${props.theme.colors.red}`},
        1px 2px 11px 0 rgba(0, 0, 0, 0.5);
      text-decoration: none;
      color: ${props => `${props.theme.colors.grey}`};
    }
  }
`

const Button = props => (
  <Wrapper>
    <Link to={props.path}>{props.text}</Link>
  </Wrapper>
)

Button.propTypes = {
  path: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
}

export default Button
