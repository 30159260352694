import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Container from './Container'
import Button from './Button'
import Img from 'gatsby-image'

const IndexWrapper = styled.section`
  background-size: cover;
  height: 440px;
  position: relative;

  @media (max-width: ${props => props.theme.sizes.tablet}) {
    height: 400px;
  }
  @media (max-width: ${props => props.theme.sizes.mobile}) {
    height: 360px;
  }

  section {
    flex-direction: column;
    justify-content: flex-end;
    padding-bottom: 60px;
    position: relative;
    z-index: 2;
    @media (max-width: ${props => props.theme.sizes.tablet}) {
      padding-bottom: 40px;
    }
    @media (max-width: ${props => props.theme.sizes.mobile}) {
      padding-bottom: 20px;
    }

    .content {
      width: 600px;
      max-width: 100%;
    }
    h1 {
      color: white;
      font-size: 48px;
      line-height: 54px;
      letter-spacing: 3px;
      margin: 0;
    }
    p {
      color: white;
      font-size: 15px;
      margin: 0;
    }
    .buttons {
      display: flex;
      margin-top: 1rem;
      @media (max-width: ${props => props.theme.sizes.mobile}) {
        justify-content: space-between;
        div {
          margin: 0 !important;
          width: 100%;
          padding: 2px;
          a {
            width: 100%;
          }
        }
      }
      div {
        margin-right: 1rem;
      }
    }
  }
`
const FleetWrapper = styled.section`
  background-size: cover;
  height: 360px;
  position: relative;

  @media (max-width: ${props => props.theme.sizes.tablet}) {
    height: 320px;
  }
  @media (max-width: ${props => props.theme.sizes.mobile}) {
    height: 280px;
  }

  section {
    z-index: 2;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    color: white;
    .title {
      z-index: 2;
      display: flex;
      justify-content: center;
      align-items: center;
      h1 {
        color: white;
        margin: 0;
        font-size: 48px;
        margin-left: 1rem;
        position: relative;
        top: 4px;
      }
      img {
      }
    }
  }
`
const TertiaryWrapper = styled.section`
  background: ${props => props.theme.colors.grey};
  padding: 1rem 0;
  section {
    h1 {
      color: white;
      margin: 0;
      font-size: 48px;
    }
    center {
      width: 100%;
    }
  }
`

const BgImg = styled(Img)`
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 0;
  height: 440px;
  @media (max-width: ${props => props.theme.sizes.tablet}) {
    height: 400px;
  }
  @media (max-width: ${props => props.theme.sizes.mobile}) {
    height: 360px;
  }

  & > img {
    object-fit: 'cover' !important;
    object-position: '50% 50%' !important;
  }
  &::before {
    content: '';
    background: rgba(34, 35, 35, 0.65);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    z-index: 1;

`

const FleetImg = styled(Img)`
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 0;
  height: 360px;
  @media (max-width: ${props => props.theme.sizes.tablet}) {
    height: 320px;
  }
  @media (max-width: ${props => props.theme.sizes.mobile}) {
    height: 280px;
  }

  & > img {
    object-fit: 'cover' !important;
    object-position: '50% 50%' !important;
  }
  &::before {
    content: '';
    background: rgba(34, 35, 35, 0.65);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
  }
`

class Hero extends React.Component {
  static propTypes = {
    heroStyle: PropTypes.oneOf(['index', 'fleet', 'tertiary']),
    title: PropTypes.string,
    description: PropTypes.string,
    background: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    icon: PropTypes.object,
    center: PropTypes.bool,
  }
  _renderHeroContent = () => {
    let { heroStyle, title, background, center, description } = this.props
    if (heroStyle === 'index') {
      return (
        <IndexWrapper>
          <BgImg fluid={background} backgroundColor={'#232324'} />
          <Container>
            <div className="content">
              <h1>{title}</h1>
              {description && <p>{description}</p>}
              <div className="buttons">
                <Button path="/fleet/land" text="Land" />
                <Button path="/fleet/air" text="Air" />
                <Button path="/fleet/sea" text="Sea" />
                <Button path="/fleet" text="All" />
              </div>
            </div>
          </Container>
        </IndexWrapper>
      )
    } else if (heroStyle === 'fleet') {
      let { background, title, icon } = this.props
      return (
        <FleetWrapper>
          <FleetImg fluid={background} backgroundColor={'#232324'} />
          <Container>
            <div className="title">
              {icon && <Img fixed={icon} />}
              <h1>{title}</h1>
            </div>
          </Container>
        </FleetWrapper>
      )
    } else if (heroStyle === 'tertiary') {
      return (
        <TertiaryWrapper>
          <Container>
            {center && (
              <center>
                <h1>{title}</h1>
              </center>
            )}
            {!center && <h1>{title}</h1>}
          </Container>
        </TertiaryWrapper>
      )
    }
  }

  render() {
    return this._renderHeroContent()
  }
}

export default Hero
